.card-title {
  text-transform: uppercase;
}

.card-content-list label {
  color: #fff;
  font-size: 20px;
  width: 4em;
}

nav {
  background-color: #58609a;
}

nav .nav-wrapper {
  width: 80%;
  margin: auto;
}

.page-footer {
  background-color: #58609a;
}

.card.blink {
  background-color: #58609a !important;
}